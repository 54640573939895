import React, { useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withList from '../../../../../pages/moduleable/modules/list/hoc/withList'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import CountDown from '../../../../../components/count-down'
import { Iconfont } from '../../../../../components/icon/iconfont'
import { FormattedMessage } from 'react-intl'
import withSource from '../../../../../hocs/source-scoll'
import ListSwiperProducts from './components/list-swiper-products'
import FlashProduct from '../../../../../desktop/pages/listing/components/item/flash-item'
import { getSensorsUrl } from '../../../../../utils/sensor'


const FlashTitle = withSource(props => {
    useStyles(classes)
    const { offset, innerRef, sensors, url, dark } = props

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: sensors?.type,
            resource_content: sensors?.refId,
            resource_position: sensors?.position,
        })
    }, [])

    return <a ref={innerRef}
        data-position={sensors?.position}
        data-type={sensors?.type}
        data-content={sensors?.refId}
        data-title={sensors?.title}
        className={classes.Hd} href={url} onClick={clickHandle}>
        <div className={classes.Name}>
            <Iconfont className={classes.Icon}>&#xe76a;</Iconfont>
            <span className={classes.Text} style={{
                color: dark ? '#fff' : undefined,
            }}>
                <FormattedMessage id="flash_sale" defaultMessage="Flash Deals" />
            </span>
        </div>
        <div className={`${classes.CountDown} ${dark? classes.Dark:''}`}>
            <span className={classes.Text}>
                <FormattedMessage id="ends_in" defaultMessage="Ends In" />
            </span>
            {
                offset && <CountDown showDay showHour offset={offset} />
            }
            <Iconfont className={classes.Icon}>&#xe690;</Iconfont>
        </div>
    </a>
})

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const url = data?.href


    const dark = !!data?.dark
    // const dark = true
    let backgroundImage

    if (dark) {
        backgroundImage = `url(${data?.backgroundUrl})`
    }

    //  backgroundImage = `url(https://image.geeko.ltd/webp/chicme/20240801083152825174.jpg)`

    const pluseStyles = backgroundImage ? {
        paddingLeft: 20,
        paddingRight: 20,
    } : {}

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const offset = products?.[0]?.promotion?.endTime - products?.[0]?.promotion?.serverTime



    return <div ref={innerRef} id={data?.id} className={`${!data?.full ? 'MaxWith' : ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles, ...pluseStyles, backgroundImage }}>
        <div className={`${classes.FlashContainer} `}>
            <FlashTitle sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }} offset={offset} url={url} dark={dark} />

            <div className={`${classes.ListContainer2}`} style={{ ...listStyles }}>
                <ListSwiperProducts
                    items={products}
                    slidesPerView={5}
                    showBgNavigation
                    outline={dark}
                    moreUrl={getSensorsUrl(url, {
                        resourcepage_title: sensors?.title,
                        resource_type: '1',
                        resource_content: data?.refId,
                        resource_position: sensors?.position,
                    })}
                    render={(item, index) => {
                        return <FlashProduct isTypeFlash={true} sensors={
                            {
                                resourcepage_title: sensors?.title,
                                resource_position: sensors?.position,
                                resource_content: data?.refId,
                                resource_type: '1',
                            }
                        } column={data?.refId} product={item} position={index} />
                    }}
                />
            </div>
        </div>

    </div>
}))